<template>
  <div class="mt-3">
    <b-row>
      <b-col cols="12">
        <b-row class="mb-4">
          <b-col cols="12">
            <div class="d-flex justify-content-end py-3">
              <b-button @click="doExport" variant="success" class="mr-3">Export Excel</b-button>
              <b-button @click="$router.push('/depature-group/' + $route.params.id + '/edit')" class="text-white mr-3" variant="primary" deep-hidden>Tambah Jamaah</b-button>
              <b-button @click="$router.push('/depature-group')" class="text-white" variant="warning">Kembali</b-button>
            </div>
            <b-card style="width:100%;" class="mx-auto" header-tag="header">
              <span class="h5">Detail Kelompok Keberangkatan</span>
              <div class="mt-3">
                <b-row>
                  <b-col cols="3"><span>Nama Kelompok </span></b-col>
                  <b-col cols="1">:</b-col>
                  <b-col
                    ><span>{{ data.name }}</span></b-col
                  >
                </b-row>
                <b-row>
                  <b-col cols="3"><span>Estimasi Keberangkatan </span></b-col>
                  <b-col cols="1">:</b-col>
                  <b-col
                    ><span>{{ data.date }}</span></b-col
                  >
                </b-row>
              </div>
              <div id="paginated-table" class="mt-4">
                <el-table
                  border
                  stripe
                  style="width: 100%"
                  :data="data.detail"
                  empty-text="Belum ada data jama'ah"
                >
                  <el-table-column label="Jama'ah" align="center" width="150">
                    <template #default="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="JenisKelamin" align="center" width="150">
                    <template #default="scope">
                      {{ scope.row.gender }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Nik" align="center" width="150">
                    <template #default="scope">
                      {{ scope.row.nik }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Status Vaksin" align="center" width="150">
                    <template #default="scope">
                      {{ 'Lengkap' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Jenis Vaksin" align="center" width="150">
                    <template #default="scope">
                      {{
                        scope.row.document.filter((data, index) => {
                          if (index > 1) return true
                        }).map(data => data.additionals[0].additional_value).join(',')
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Tanggal Vaksin" align="center" width="150">
                    <el-table-column label="Dosis 1" align="center" width="150">
                      <template #default="scope">
                        {{ scope.row.document[4].additionals[1].additional_value }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Dosis 2" align="center" width="150">
                      <template #default="scope">
                        {{ scope.row.document[2].additionals[1].additional_value }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Dosis 3" align="center" width="150">
                      <template #default="scope">
                        {{ scope.row.document[2].additionals[1].additional_value }}
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="Tempat Lahir" align="center" width="150">
                    -
                  </el-table-column>
                  <el-table-column label="Tanggal Lahir" align="center" width="150">
                    <template #default="scope">
                      {{ scope.row.dob }}
                    </template>
                  </el-table-column>
                  <el-table-column label="No. Passpor" align="center" width="150">
                    <template #default="scope">
                        {{ scope.row.document[0].additionals[0].additional_value }}
                      </template>
                  </el-table-column>
                  <el-table-column label="Tanggal Pengeluran" align="center" width="150">
                    <template #default="scope">
                        {{ scope.row.document[0].additionals[2].additional_value }}
                      </template>
                  </el-table-column>
                  <el-table-column label="Tanggal Kadaluarsa" align="center" width="150">
                    <template #default="scope">
                        {{ scope.row.document[0].additionals[1].additional_value }}
                      </template>
                  </el-table-column>
                  <el-table-column label="Kantor Penerbitan" align="center" width="150">
                    <template #default="scope">
                        {{ scope.row.document[0].additionals[3].additional_value }}
                      </template>
                  </el-table-column>
                  <el-table-column label="Hubungan Keluarga" align="center" width="150">
                    <template #default="scope">
                      {{ scope.row.marriage_status }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Miningitis" align="center" width="150">
                    -
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="70"
                    align="center"
                    label="Aksi"
                  >
                    <template #default="scope">
                      <font-awesome-icon
                        @click="deletePilgrim(scope.row.id)"
                        class="pointer"
                        style="color: #e63757"
                        icon="trash"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from "@/_helpers";
import detailGroupInput from "@/components/DetailGroupInput.vue";
import DepatureGroup from "@/models/DepatureGroup.js";

const DepatureGroupModel = new DepatureGroup();

export default {
  components: {
    detailGroupInput,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      data: null,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    deletePilgrim: function(id) {
      DepatureGroupModel
        .removePilgrim(id)
        .then((resp) => {
          this.$store.dispatch(
            "notification/success",
            "Jama'ah Berhasil dihapus."
          );
          this.getDetail();
        })
        .catch((err) => {
          this.$store.dispatch("notification/error", err);
        });
    },
    doExport: function() {
      DepatureGroupModel.exportExcel(this.$route.params.id)
    },
    getDetail() {
      this.isLoading = true;
      DepatureGroupModel.find(this.$route.params.id)
        .then((resp) => {
          this.isLoading = false;
          this.data = resp;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
